import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Camo Clash Paintball Arena Hakkında
			</title>
			<meta name={"description"} content={"Her Oyunun Destansı Bir Masal Olduğu Camo Clash Chronicle'a Dalış Yapın"} />
			<meta property={"og:title"} content={"Hakkımızda | Camo Clash Paintball Arena Hakkında"} />
			<meta property={"og:description"} content={"Her Oyunun Destansı Bir Masal Olduğu Camo Clash Chronicle'a Dalış Yapın"} />
			<meta property={"og:image"} content={"https://jake-rembis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jake-rembis.com/img/4905919.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://jake-rembis.com/img/4905919.png"} />
			<meta name={"msapplication-TileImage"} content={"https://jake-rembis.com/img/4905919.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--primary"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Hakkımızda
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Camo Clash Paintball Arena, heyecan verici bir paintball sığınağına dönüştürülen boş bir tuval olan engebeli bir arazi olarak başladı. Yıllar süren işçilik ve mükemmelleştirme sayesinde, alanlarımız macera arayanların cesaretlerini ve stratejilerini test etmek için bir araya geldiği efsanevi bir arena haline geldi.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://jake-rembis.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Bizim Felsefemiz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Oyunun gücüne ve oyunun heyecanına inanıyoruz. Paintball bizim için sadece bir spor değil - kendine meydan okumanın, strateji ve heyecan dolu bir dünyaya adım atmanın ve bir takım olarak birleşip galip gelmenin bir yolu.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
					as="h2"
				>
					Camo Clash Deneyimi
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Arenamızın her köşesi geçmiş savaşların ve zaferlerin hikayesini anlatır ve yeni kahramanların iz bırakmasını bekler.
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 22px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Olağanüstü Oyun Oynama
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Sahalarımız, hızlı düşünmenizi ve ekip çalışmanızı zorlayan senaryolarla sürükleyici bir deneyim sunmak üzere tasarlanmıştır.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					margin="0px 0px 22px 0px"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Meraklılar İçin Bir Merkez
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Camo Clash, ister ilk kez bir işaretleyici tutuyor olun, ister deneyimli bir usta olun, paintball tutkusunu paylaşanlar için bir topluluktur.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Güvenlik Taahhüdü
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Her şeyden önce, oyuncularımızın refahına öncelik veriyor ve her oyunun en yüksek güvenlik standartlarıyla yürütülmesini sağlıyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://jake-rembis.com/img/3.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Vizyonumuz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Camo Clash Paintball Arena, oyuncularımızla birlikte büyümeye, arenalarımızı sürekli geliştirmeye ve paintball heyecanının herkes tarafından yaşanabileceği bir alanı teşvik etmeye kararlıdır. Anıların yaratıldığı, dostların ve düşmanların oyun sevgisi için bir araya gelebildiği bir yer olmayı hedefliyoruz.
			</Text>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});